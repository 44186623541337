<template>
 <div class="bg-white">
  <NavBarNew></NavBarNew>
  <RegistrationForm></RegistrationForm>
  <Footer></Footer>
  </div>
</template>

<script>
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import RegistrationForm from "@/components/RegistrationScreen/RegistrationForm.vue";
import Footer from "@/components/HomeScreen/Footer.vue";

export default {
  name: "Products Description",
  components: {
    NavBarNew,
    RegistrationForm,
    Footer,
  },
};
</script>