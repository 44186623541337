<template>
  <div class="pt-40">
    <div class="flex justify-center">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScsC4PllzEx44UMj7tEvvkU2mcwkaU0qNPOLSqZOVRy3gt5XA/viewform?usp=pp_url&entry.1958480992=Kodim?embedded=true"
        width="640"
        height="2448"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Loading…</iframe
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>